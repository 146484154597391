/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'basket2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.929 1.757a.5.5 0 10-.858-.514L2.217 6H.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h.623l1.844 6.456A.75.75 0 003.69 15h8.622a.75.75 0 00.722-.544L14.877 8h.623a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1.717L10.93 1.243a.5.5 0 10-.858.514L12.617 6H3.383zM4 10a1 1 0 012 0v2a1 1 0 11-2 0zm3 0a1 1 0 012 0v2a1 1 0 11-2 0zm4-1a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1"/>',
    },
});
